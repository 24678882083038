import * as React from 'react'
import Seo from '../components/help/Seo'
import Layout from '../components/help/Layout/Layout'
import Page404 from '../components/pages/Page404/Page404'
import { useDataHeaderByHy } from '../components/ui/Header/useDataHeaderByHy'

const NotFoundPage = () => {
    const lang = 'en'

    return (
        <Layout lang={lang} hiddenFooter>
            <Seo title='404: Not found' />
            <Page404 lang={lang} />
        </Layout>
    )
}

export default NotFoundPage
