import React from 'react'
import cn from 'classnames'
import * as classes from './Button.module.scss'

export const Button = ({ children, onClick, type, disabled, className }) => {
    return (
        <button className={cn(classes.Button, className)} type={type} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    )
}
