// extracted by mini-css-extract-plugin
export var Section = "Page404-module--Section--cXJsx";
export var Image = "Page404-module--Image--vqvpS";
export var Title = "Page404-module--Title--cLt5m";
export var Content = "Page404-module--Content--ziCFz";
export var Text = "Page404-module--Text--gOPEi";
export var Footer = "Page404-module--Footer--ipfXD";
export var Copyright = "Page404-module--Copyright--I8RTf";
export var Policy = "Page404-module--Policy--aOEn8";
export var Author = "Page404-module--Author--4yRNG";
export var arrowLeft = "Page404-module--arrowLeft--Z9yE5";
export var arrowRight = "Page404-module--arrowRight--9zGgl";