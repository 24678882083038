import React from 'react'
import { Link, navigate } from 'gatsby'
import * as classes from './Page404.module.scss'
import Container from '../../ui/Container/Container'
import Header from '../../ui/Header/Header'
import { Button } from '../../ui/Button/Button'
import { content } from './content'

function Page404({ lang }) {
    return (
        <section className={classes.Section}>
            <Header lang={lang} page404={true} />

            <div className={classes.Image}>
                <div className={classes.Title}>404</div>
            </div>

            <div className={classes.Content}>
                <p className={classes.Text}>{content.text}</p>
                <Button onClick={() => navigate('/')}>{content.textButon}</Button>
            </div>

            <Container>
                <div className={classes.Footer}>
                    <p className={classes.Copyright}>{content.footer.copy}</p>

                    <div className={classes.Policy}>
                        <Link to={content.footer.policy.href}>{content.footer.policy.name}</Link>
                    </div>

                    <p className={classes.Author}>
                        Site by
                        <a href='https://d-creator.ru' target='_blank' rel='noreferrer'>
                            Design Creator
                        </a>
                    </p>
                </div>
            </Container>
        </section>
    )
}

export default Page404
